<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div class="wrapper">
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="mb-0">Postulación</h5>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active">Tif</li>
                  <li class="breadcrumb-item active">Operaciones</li>
                  <li class="breadcrumb-item active">Postulaciones</li>
                  <li class="breadcrumb-item active">Asignar Vehículos</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section class="content">
          <div class="container-fluid">
            <div class="card">
              <div class="card-header pt-2 pb-2">
                <div class="row">
                  <div class="col-md-9" v-if="getDetSolicitud.length > 0">
                    <h3 class="card-title">
                      <small>
                        <b>Bloque:</b>
                        {{
                          getDetSolicitud[0].tif_solicitudes_vehiculos.bloque
                            .nombre
                        }}
                      </small>
                      |
                      <small>
                        <b>Empresa:</b>
                        {{
                          getDetSolicitud[0].tif_solicitudes_vehiculos.empresa
                            .razon_social
                        }}
                      </small>
                      |
                      <small>
                        <b>Fecha:</b>
                        {{
                          getDetSolicitud[0].tif_solicitudes_vehiculos.fecha_ini
                        }}
                        -
                        {{
                          getDetSolicitud[0].tif_solicitudes_vehiculos.fecha_fin
                        }}
                      </small>
                    </h3>
                  </div>
                  <div class="col-md-3">
                    <div class="btn-group float-right">
                      <button
                        type="button"
                        class="btn bg-navy"
                        @click="enviarCorreo()"
                        v-if="
                          porcentaje == 100 &&
                            $store.getters.can(
                              'tif.postulaciones.enviarCorreo'
                            ) &&
                            act != 2
                        "
                      >
                        <i class="fas fa-envelope"></i>
                        <strong><br />Enviar Correo</strong>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="progress progress-xs progress-striped active"
                style="height: 12px"
              >
                <div
                  class="progress-bar progress-bar-striped progress-bar-animated"
                  role="progressbar"
                  aria-valuemin="0"
                  aria-valuemax="100"
                  :style="'width:' + porcentaje + '%'"
                >
                  {{ porcentaje }}%
                </div>
              </div>
              <div class="card-body">
                <div class="row">
                  <div
                    class="col-md-12"
                    v-for="(sol, indicesol) in getDetSolicitud"
                    :key="sol.id"
                  >
                    <div class="card card-outline collapsed-card card-success">
                      <div class="card-header pt-2 pb-2">
                        <h3 class="card-title">
                          <small>
                            <b>Tipo Vehículo:</b>
                            {{ sol.tif_tipos_vehiculos.nombre }}
                          </small>
                          |
                          <small>
                            <b>Operación:</b>
                            {{ sol.tif_operacion.Noperacion }}
                          </small>
                          |
                          <small>
                            <b>Tipo Producto:</b>
                            {{ sol.producto.nombre }}
                          </small>
                          |
                          <small>
                            <b>Ruta:</b>
                            {{ sol.tif_ruta ? sol.tif_ruta.nombre : "" }}
                          </small>
                          |
                          <small>
                            <b>Tarifa:</b>
                            {{ sol.nTarifa }}
                          </small>
                          |
                          <small>
                            <b>Cantidad de Vehículos:</b>
                            {{ sol.cantidad_vehiculo }}
                          </small>
                          |
                          <small>
                            <b>Turno:</b>
                            {{ sol.turno.nombre }}
                          </small>
                        </h3>
                        <div class="card-tools">
                          <button
                            type="button"
                            class="btn btn-tool"
                            data-card-widget="collapse"
                            data-toggle="tooltip"
                            title="Collapse"
                          >
                            <i class="fas fa-plus"></i>
                          </button>
                        </div>
                      </div>

                      <div class="card-body" style="display: none">
                        <div
                          class="col-md-12"
                          v-for="(itemCantVehi,
                          indiceCantVehi) in sol.cantidad_vehiculo"
                          :key="indiceCantVehi"
                        >
                          <div
                            class="card card-outline collapsed-card card-teal"
                          >
                            <div class="card-header pt-2 pb-2">
                              <h3 class="card-title">
                                <small>
                                  <b
                                    >{{ sol.tif_tipos_vehiculos.nombre }}
                                    -
                                  </b>
                                  {{ indiceCantVehi + 1 }}
                                </small>
                              </h3>
                              <div class="card-tools">
                                <button
                                  type="button"
                                  class="btn btn-tool"
                                  data-card-widget="collapse"
                                  data-toggle="tooltip"
                                  title="Collapse"
                                >
                                  <i class="fas fa-plus"></i>
                                </button>
                              </div>
                            </div>
                            <div
                              class="card-body table-responsive"
                              style="display: none"
                            >
                              <table
                                class="table table-bordered table-striped table-hover table-sm"
                              >
                                <thead>
                                  <tr>
                                    <!-- Día -->
                                    <th style="width: 5%" class="text-center">
                                      Día
                                    </th>
                                    <!-- Cabezote -->
                                    <th
                                      style="width: 10%"
                                      class="text-center"
                                      v-if="sol.tipo_vehiculo == 1"
                                    >
                                      Cabezote
                                      <input
                                        type="text"
                                        class="form-control form-control-sm"
                                        label="Cabezote"
                                        v-model="
                                          listasForms.slct_vehiculo.placa
                                        "
                                        :id="
                                          'slct_vehiculo' +
                                            indicesol +
                                            '_' +
                                            indiceCantVehi
                                        "
                                        @change="
                                          buscarCabezote(
                                            sol.id,
                                            indicesol,
                                            indiceCantVehi,
                                            null,
                                            sol.tipo_vehiculo
                                          )
                                        "
                                        v-show="sol"
                                      />
                                    </th>
                                    <!-- Sencillo -->
                                    <th
                                      style="width: 10%"
                                      class="text-center"
                                      v-if="sol.tipo_vehiculo == 7"
                                    >
                                      Sencillo
                                      <input
                                        type="text"
                                        class="form-control form-control-sm"
                                        label="Sencillo"
                                        v-model="
                                          listasForms.slct_vehiculo.placa
                                        "
                                        :id="
                                          'slct_vehiculo' +
                                            indicesol +
                                            '_' +
                                            indiceCantVehi
                                        "
                                        @change="
                                          buscarCabezote(
                                            sol.id,
                                            indicesol,
                                            indiceCantVehi,
                                            null,
                                            sol.tipo_vehiculo
                                          )
                                        "
                                        v-show="sol"
                                      />
                                    </th>
                                    <!-- Remolque -->
                                    <th
                                      style="width: 10%"
                                      class="text-center"
                                      v-if="sol.tipo_vehiculo == 1"
                                    >
                                      Remolque
                                      <input
                                        type="text"
                                        class="form-control form-control-sm"
                                        label="Remolque"
                                        v-model="
                                          listasForms.slct_remolque.placa
                                        "
                                        :id="
                                          'slct_remolque' +
                                            indicesol +
                                            '_' +
                                            indiceCantVehi
                                        "
                                        @change="
                                          buscarRemolque(
                                            sol.id,
                                            indicesol,
                                            indiceCantVehi,
                                            null
                                          )
                                        "
                                      />
                                    </th>
                                    <!-- DobleTroque -->
                                    <th
                                      style="width: 10%"
                                      class="text-center"
                                      v-if="sol.tipo_vehiculo == 6"
                                    >
                                      Dobletroque
                                      <input
                                        type="text"
                                        class="form-control form-control-sm"
                                        label="Dobletroquex"
                                        v-model="
                                          listasForms.slct_vehiculo.placa
                                        "
                                        :id="
                                          'slct_vehiculo' +
                                            indicesol +
                                            '_' +
                                            indiceCantVehi
                                        "
                                        @change="
                                          buscarCabezote(
                                            sol.id,
                                            indicesol,
                                            indiceCantVehi,
                                            null,
                                            sol.tipo_vehiculo
                                          )
                                        "
                                        v-show="sol"
                                      />
                                    </th>
                                    <!-- DobleTroque C.V -->
                                    <th
                                      style="width: 10%"
                                      class="text-center"
                                      v-if="
                                        sol.tipo_vehiculo == 52 ||
                                          sol.tipo_vehiculo == 53
                                      "
                                    >
                                      Dobletroque C.V
                                      <input
                                        type="text"
                                        class="form-control form-control-sm"
                                        label="Dobletroquec"
                                        v-model="
                                          listasForms.slct_vehiculo.placa
                                        "
                                        :id="
                                          'slct_vehiculo' +
                                            indicesol +
                                            '_' +
                                            indiceCantVehi
                                        "
                                        @change="
                                          buscarCabezote(
                                            sol.id,
                                            indicesol,
                                            indiceCantVehi,
                                            null,
                                            sol.tipo_vehiculo
                                          )
                                        "
                                        v-show="sol"
                                      />
                                    </th>
                                    <!-- Conductor 1 -->
                                    <th style="width: 27%" class="text-center">
                                      Conductor 1
                                      <input
                                        type="text"
                                        class="form-control form-control-sm"
                                        label="Conductor"
                                        v-model="
                                          listasForms.slct_conductor
                                            .numero_documento
                                        "
                                        :id="
                                          'slct_conductor' +
                                            indicesol +
                                            '_' +
                                            indiceCantVehi
                                        "
                                        @change="
                                          buscarConductores(
                                            sol.id,
                                            indicesol,
                                            indiceCantVehi,
                                            null
                                          )
                                        "
                                      />
                                    </th>
                                    <!-- Conductor 2 -->
                                    <th
                                      style="width: 27%"
                                      class="text-center"
                                      v-if="
                                        sol.turno.dia_turno[0].cant_horas > 12
                                      "
                                    >
                                      Conductor 2
                                      <input
                                        type="text"
                                        class="form-control form-control-sm"
                                        label="Conductor"
                                        v-model="
                                          listasForms.slct_conductor_2
                                            .numero_documento
                                        "
                                        :id="
                                          'slct_conductor_2' +
                                            indicesol +
                                            '_' +
                                            indiceCantVehi
                                        "
                                        @change="
                                          buscarConductores_2(
                                            sol.id,
                                            indicesol,
                                            indiceCantVehi,
                                            null
                                          )
                                        "
                                      />
                                    </th>
                                    <!-- Fecha -->
                                    <th
                                      style="min-width: 25%"
                                      class="text-center"
                                    >
                                      Fecha
                                    </th>
                                    <!-- Estado -->
                                    <th class="text-center" style="width: 20%">
                                      Estado
                                    </th>
                                    <!-- Acción -->
                                    <th style="width: 20%" class="text-center">
                                      Acción
                                      <br />
                                      <button
                                        class="btn btn-default btn-sm bg-frontera-top-left text-white"
                                        :id="
                                          'buttonSave' +
                                            indicesol +
                                            '_' +
                                            indiceCantVehi
                                        "
                                        @click="
                                          savePostulacion(
                                            sol.id,
                                            indicesol,
                                            indiceCantVehi,
                                            sol.tif_solicitud_vehiculo_id,
                                            sol.producto_id,
                                            sol.tif_solicitudes_vehiculos
                                              .empresa_id,
                                            sol.tif_solicitudes_vehiculos.bloque
                                              .nombre
                                          )
                                        "
                                      >
                                        <i class="fas fa-save"></i>
                                      </button>
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr
                                    v-for="(cant, indicecant) in diasPost"
                                    :class="[
                                      validarEstado(
                                        indicesol,
                                        indiceCantVehi,
                                        indicecant
                                      )
                                        ? ''
                                        : 'table-warning',
                                      validarDias(
                                        moment(
                                          sol.tif_solicitudes_vehiculos
                                            .fecha_ini
                                        )
                                          .add(indicecant, 'days')
                                          .day(),
                                        sol.turno.dia_turno
                                      )
                                        ? ''
                                        : 'table-danger',
                                    ]"
                                    :key="indicecant"
                                    :id="
                                      'tr' +
                                        indicesol +
                                        '_' +
                                        indiceCantVehi +
                                        '_' +
                                        indicecant
                                    "
                                  >
                                    <td class="text-center">
                                      {{ indicecant + 1 }}
                                    </td>
                                    <td class="text-center">
                                      <input
                                        type="text"
                                        class="form-control form-control-sm"
                                        label="placa"
                                        v-model="
                                          form1[indicesol][indiceCantVehi][
                                            indicecant
                                          ].vehiculo.placa
                                        "
                                        @change="
                                          buscarCabezote(
                                            sol.id,
                                            indicesol,
                                            indiceCantVehi,
                                            indicecant,
                                            sol.tipo_vehiculo
                                          )
                                        "
                                        v-if="
                                          form1[indicesol][indiceCantVehi][
                                            indicecant
                                          ].vehiculo &&
                                            validarDias(
                                              moment(
                                                sol.tif_solicitudes_vehiculos
                                                  .fecha_ini
                                              )
                                                .add(indicecant, 'days')
                                                .day(),
                                              sol.turno.dia_turno
                                            )
                                        "
                                        :disabled="
                                          selectVehi !=
                                            indicesol +
                                              '_' +
                                              indiceCantVehi +
                                              '_' +
                                              indicecant
                                        "
                                      />
                                      <div
                                        v-if="
                                          form1[indicesol][indiceCantVehi][
                                            indicecant
                                          ].estado == 4
                                        "
                                        class="text-center"
                                      >
                                        <span class="badge badge-danger"
                                          >Vehículo esta pendiente por
                                          autorizar</span
                                        >
                                      </div>
                                    </td>
                                    <td
                                      class="text-center"
                                      v-if="sol.tipo_vehiculo == 1"
                                    >
                                      <input
                                        type="text"
                                        class="form-control form-control-sm"
                                        label="Remolque"
                                        v-if="
                                          form1[indicesol][indiceCantVehi][
                                            indicecant
                                          ].remolque &&
                                            validarDias(
                                              moment(
                                                sol.tif_solicitudes_vehiculos
                                                  .fecha_ini
                                              )
                                                .add(indicecant, 'days')
                                                .day(),
                                              sol.turno.dia_turno
                                            )
                                        "
                                        :disabled="
                                          selectVehi !=
                                            indicesol +
                                              '_' +
                                              indiceCantVehi +
                                              '_' +
                                              indicecant
                                        "
                                        v-model="
                                          form1[indicesol][indiceCantVehi][
                                            indicecant
                                          ].remolque.placa
                                        "
                                        @change="
                                          buscarRemolque(
                                            sol.id,
                                            indicesol,
                                            indiceCantVehi,
                                            indicecant
                                          )
                                        "
                                      />
                                    </td>
                                    <td>
                                      <div class="row">
                                        <div class="col-md-6">
                                          <input
                                            type="text"
                                            class="form-control form-control-sm"
                                            label="Coductor 1"
                                            v-if="
                                              form1[indicesol][indiceCantVehi][
                                                indicecant
                                              ].conductor1 &&
                                                validarDias(
                                                  moment(
                                                    sol
                                                      .tif_solicitudes_vehiculos
                                                      .fecha_ini
                                                  )
                                                    .add(indicecant, 'days')
                                                    .day(),
                                                  sol.turno.dia_turno
                                                )
                                            "
                                            v-model="
                                              form1[indicesol][indiceCantVehi][
                                                indicecant
                                              ].conductor1.numero_documento
                                            "
                                            @change="
                                              buscarConductores(
                                                sol.id,
                                                indicesol,
                                                indiceCantVehi,
                                                indicecant
                                              )
                                            "
                                            :disabled="
                                              selectVehi !=
                                                indicesol +
                                                  '_' +
                                                  indiceCantVehi +
                                                  '_' +
                                                  indicecant
                                            "
                                          />
                                        </div>
                                        <div class="col-md-6">
                                          <span
                                            v-if="
                                              form1[indicesol][indiceCantVehi][
                                                indicecant
                                              ].conductor1 &&
                                                validarDias(
                                                  moment(
                                                    sol
                                                      .tif_solicitudes_vehiculos
                                                      .fecha_ini
                                                  )
                                                    .add(indicecant, 'days')
                                                    .day(),
                                                  sol.turno.dia_turno
                                                )
                                            "
                                          >
                                            {{
                                              form1[indicesol][indiceCantVehi][
                                                indicecant
                                              ].conductor1.nombre
                                            }}
                                          </span>
                                          <span v-else> </span>
                                        </div>
                                      </div>
                                    </td>
                                    <td
                                      class="text-center"
                                      v-if="
                                        form1[indicesol] &&
                                          form1[indicesol][indiceCantVehi][
                                            indicecant
                                          ].cant_horas > 12
                                      "
                                    >
                                      <div class="row">
                                        <div class="col-md-6">
                                          <input
                                            type="text"
                                            class="form-control form-control-sm"
                                            label="Coductor 2"
                                            :disabled="
                                              selectVehi !=
                                                indicesol +
                                                  '_' +
                                                  indiceCantVehi +
                                                  '_' +
                                                  indicecant
                                            "
                                            v-if="
                                              form1[indicesol][indiceCantVehi][
                                                indicecant
                                              ].conductor2 &&
                                                validarDias(
                                                  moment(
                                                    sol
                                                      .tif_solicitudes_vehiculos
                                                      .fecha_ini
                                                  )
                                                    .add(indicecant, 'days')
                                                    .day(),
                                                  sol.turno.dia_turno
                                                )
                                            "
                                            v-model="
                                              form1[indicesol][indiceCantVehi][
                                                indicecant
                                              ].conductor2.numero_documento
                                            "
                                            @change="
                                              buscarConductores_2(
                                                sol.id,
                                                indicesol,
                                                indiceCantVehi,
                                                indicecant
                                              )
                                            "
                                          />
                                        </div>
                                        <div class="col-md-6">
                                          <span
                                            v-if="
                                              form1[indicesol][indiceCantVehi][
                                                indicecant
                                              ].conductor2 &&
                                                validarDias(
                                                  moment(
                                                    sol
                                                      .tif_solicitudes_vehiculos
                                                      .fecha_ini
                                                  )
                                                    .add(indicecant, 'days')
                                                    .day(),
                                                  sol.turno.dia_turno
                                                )
                                            "
                                          >
                                            {{
                                              form1[indicesol][indiceCantVehi][
                                                indicecant
                                              ].conductor2.nombre
                                            }}
                                          </span>
                                          <span v-else> </span>
                                        </div>
                                      </div>
                                    </td>
                                    <td class="text-center">
                                      <input
                                        type="text"
                                        class="form-control form-control-sm"
                                        label="fecha"
                                        v-model="
                                          form1[indicesol][indiceCantVehi][
                                            indicecant
                                          ].fecha_ver
                                        "
                                        v-if="
                                          form1[indicesol][indiceCantVehi][
                                            indicecant
                                          ].fecha_ver
                                        "
                                        disabled
                                      />
                                    </td>
                                    <td class="text-center">
                                      {{
                                        form1[indicesol][indiceCantVehi][
                                          indicecant
                                        ].nEstado
                                      }}
                                    </td>
                                    <td class="text-center">
                                      <button
                                        class="btn btn-default btn-sm bg-navy"
                                        v-if="
                                          $store.getters.can(
                                            'tif.postulaciones.create'
                                          ) &&
                                            form1[indicesol][indiceCantVehi][
                                              indicecant
                                            ].fechaSoli > hoy &&
                                            selectVehi !=
                                              indicesol +
                                                '_' +
                                                indiceCantVehi +
                                                '_' +
                                                indicecant
                                        "
                                        @click="
                                          editDetPost(
                                            indicesol,
                                            indiceCantVehi,
                                            indicecant,
                                            moment(
                                              sol.tif_solicitudes_vehiculos
                                                .fecha_ini
                                            )
                                              .add(indicecant, 'days')
                                              .format('YYYY-MM-DD')
                                          )
                                        "
                                      >
                                        <i class="fas fa-edit"></i>
                                      </button>
                                      <button
                                        class="btn btn-default btn-sm bg-frontera-top-left text-white"
                                        v-if="
                                          $store.getters.can(
                                            'tif.postulaciones.create'
                                          ) &&
                                            validarDias(
                                              moment(
                                                sol.tif_solicitudes_vehiculos
                                                  .fecha_ini
                                              )
                                                .add(indicecant, 'days')
                                                .day(),
                                              sol.turno.dia_turno
                                            ) &&
                                            selectVehi ==
                                              indicesol +
                                                '_' +
                                                indiceCantVehi +
                                                '_' +
                                                indicecant
                                        "
                                        @click="
                                          saveDetPostulacion(
                                            sol.id,
                                            indicesol,
                                            indiceCantVehi,
                                            indicecant,
                                            sol.tipo_vehiculo,
                                            sol.producto_id,
                                            sol.tif_solicitudes_vehiculos
                                              .empresa_id,
                                            sol.tif_solicitudes_vehiculos.bloque
                                              .nombre
                                          )
                                        "
                                      >
                                        <i class="fas fa-save"></i>
                                      </button>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-footer">
                <div class="row">
                  <button class="btn bg-secondary col-md-1" @click="back()">
                    <i class="fas fa-reply"></i><br />Volver
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapActions, mapState } from "vuex";
import Loading from "../../../../components/Loading";
import moment from "moment";
import "moment/locale/es";
import $ from "jquery";

export default {
  name: "TifPostulacionesForm",
  components: {
    Loading,
  },
  data() {
    return {
      cargando: false,
      count: null,
      count2: null,
      porcentaje: null,
      pendientes: 0,
      res: {},
      accion: 1,
      act: 1,
      form: {
        vehiculo: {},
        remolque: {},
        conductor1: {},
        nombre1: {},
        conductor2: {},
        nombre2: {},
      },
      params: null,
      diasPost: null,
      getDetSolicitud: {},
      nominacion_id: null,
      empresa_id: null,
      postulaciones: [],
      slctVehi: null,
      listasForms: {
        slct_vehiculo: {},
        slct_remolque: {},
        slct_conductor: {},
        slct_conductor_2: {},
      },
      flag: false,
      form1: [],
      hoy: moment().format("YYYY-MM-DD"),
      postEmpresa: [],
      moment: moment,
      selectVehi: null,
    };
  },

  methods: {
    async getIndex() {
      this.accion = 1;
      this.act = this.$route.params.act;
      this.cargando = true;
      this.params = null;
      await axios
        .get("/api/tif/postulaciones/getDetSolicitud", {
          params: {
            id: this.$route.params.solicitud.id,
          },
        })
        .then(async (response) => {
          this.count = 0;
          this.count2 = 0;
          this.getDetSolicitud = response.data;
          this.cargando = false;

          let fecha1 = await moment(
            this.getDetSolicitud[0].tif_solicitudes_vehiculos.fecha_ini
          );

          let fecha2 = await moment(
            this.getDetSolicitud[0].tif_solicitudes_vehiculos.fecha_fin
          );

          this.diasPost = (await fecha2.diff(fecha1, "days")) + 1;
          await this.buildArrayForm();
          await this.barraPorcentaje();
        });
    },

    barraPorcentaje() {
      // Calculo de la barra de progreso
      let sum = 1;
      this.porcentaje = null;
      this.pendientes = 0;
      let contador = 0;
      this.form1.forEach((postu) => {
        postu.forEach((t) => {
          contador++;
          if (t.flag === true) {
            this.pendientes += sum;
          }
        });
      });
      this.porcentaje = parseInt((this.pendientes / contador) * 100);
    },

    async buildArrayForm() {
      let arrayPost = [];
      let form = {};
      for (let i = 0; i < this.getDetSolicitud.length; i++) {
        let arrayCant = [];
        for (let j = 0; j < this.getDetSolicitud[i].cantidad_vehiculo; j++) {
          let arrayDias = [];
          for (let k = 0; k < this.diasPost; k++) {
            let fechaIni = null;
            let fechaFin = null;
            let horaIni = null;
            let horaFin = null;
            let fechaIniDia = null;

            fechaIni = moment(
              this.getDetSolicitud[i].tif_solicitudes_vehiculos.fecha_ini
            ).add(k, "days");

            fechaIniDia = fechaIni.day();
            if (fechaIniDia == 0) {
              fechaIniDia = 7;
            }
            let diaDescanso = fechaIniDia;
            for (
              let d = 0;
              d < this.getDetSolicitud[i].turno.dia_turno.length;
              d++
            ) {
              if (
                fechaIniDia ==
                this.getDetSolicitud[i].turno.dia_turno[d].dia_semana
              ) {
                horaIni = this.getDetSolicitud[i].turno.dia_turno[d]
                  .hora_inicial;

                horaFin = this.getDetSolicitud[i].turno.dia_turno[d].hora_final;
                diaDescanso = null;
              }

              if (horaIni > horaFin) {
                fechaFin = moment(fechaIni).add(1, "days");
              } else {
                fechaFin = fechaIni;
              }
            }

            let fechaHoraFin =
              moment(fechaFin).format("YYYY-MM-DD") + " " + horaFin;

            let fechaHoraIni =
              moment(fechaIni).format("YYYY-MM-DD") + " " + horaIni;

            let fecha_ver = moment(fechaIni).format("dddd, LL");

            let fecha = fechaIni;

            let fechaSoli = moment(fecha).format("YYYY-MM-DD");

            form = {
              cant_horas: this.getDetSolicitud[i].turno.dia_turno[0].cant_horas,
              flag: false,
              vehiculo: null,
              remolque: null,
              conductor: null,
              conductor_2: null,
              fecha_ini: fechaHoraIni,
              fecha_fin: fechaHoraFin,
              indice_operacion: i,
              orden: j,
              pos_dia: k,
              estado: 1,
              fecha_ver,
              fecha,
              fechaSoli,
              fechaHoraIni,
              fechaHoraFin,
              diaDescanso,
            };

            if (this.act == 2) {
              $("#buttonSave" + i + "_" + j).prop("disabled", true);
              $("#slct_vehiculo" + i + "_" + j).prop("disabled", true);
              $("#slct_conductor" + i + "_" + j).prop("disabled", true);
              $("#slct_conductor_2" + i + "_" + j).prop("disabled", true);
              $("#slct_remolque" + i + "_" + j).prop("disabled", true);
            }

            for (
              let x = 0;
              x < this.getDetSolicitud[i].tif_det_postulaciones.length;
              x++
            ) {
              if (
                this.getDetSolicitud[i].tif_det_postulaciones[x].orden == j &&
                this.getDetSolicitud[i].tif_det_postulaciones[x].pos_dia == k
              ) {
                /* Remolque */
                let remolque = null;
                if (this.getDetSolicitud[i].tipo_vehiculo == 1) {
                  remolque = {
                    id: this.getDetSolicitud[i].tif_det_postulaciones[x]
                      .remolque_id,
                    placa: this.getDetSolicitud[i].tif_det_postulaciones[x]
                      .remolque.placa,
                  };
                }

                /* Conductor */
                let conductor = null;
                if (
                  this.getDetSolicitud[i].tif_det_postulaciones[x]
                    .conductor_id &&
                  this.getDetSolicitud[i].tif_det_postulaciones[x].conductor
                ) {
                  conductor = {
                    id: this.getDetSolicitud[i].tif_det_postulaciones[x]
                      .conductor_id,
                    numero_documento: this.getDetSolicitud[i]
                      .tif_det_postulaciones[x].conductor.numero_documento,
                    nombre:
                      this.getDetSolicitud[i].tif_det_postulaciones[x].conductor
                        .nombres +
                      " " +
                      this.getDetSolicitud[i].tif_det_postulaciones[x].conductor
                        .apellidos,
                  };
                }

                /* Conductor 2 */
                let conductor2 = null;
                if (
                  this.getDetSolicitud[i].tif_det_postulaciones[x]
                    .conductor_2_id &&
                  this.getDetSolicitud[i].tif_det_postulaciones[x].conductor2
                ) {
                  conductor2 = {
                    id: this.getDetSolicitud[i].tif_det_postulaciones[x]
                      .conductor_2_id,
                    numero_documento: this.getDetSolicitud[i]
                      .tif_det_postulaciones[x].conductor2.numero_documento,
                    nombre:
                      this.getDetSolicitud[i].tif_det_postulaciones[x]
                        .conductor2.nombres +
                      " " +
                      this.getDetSolicitud[i].tif_det_postulaciones[x]
                        .conductor2.apellidos,
                  };
                }

                form = {
                  id: this.getDetSolicitud[i].tif_det_postulaciones[x].id,
                  flag: true,
                  cant_horas: this.getDetSolicitud[i].turno.dia_turno[0]
                    .cant_horas,
                  vehiculo: {
                    id: this.getDetSolicitud[i].tif_det_postulaciones[x]
                      .vehiculo_id,
                    placa: this.getDetSolicitud[i].tif_det_postulaciones[x]
                      .vehiculo.placa,
                  },
                  remolque: remolque,
                  conductor1: conductor,
                  conductor2: conductor2,
                  empresa_id: this.getDetSolicitud[i].tif_solicitudes_vehiculos
                    .empresa_id,
                  estado: this.getDetSolicitud[i].tif_det_postulaciones[x]
                    .estado,
                  nEstado: this.getDetSolicitud[i].tif_det_postulaciones[x]
                    .nEstado,
                  indice_operacion: i,
                  orden: j,
                  pos_dia: k,
                  fecha_ver,
                  fecha,
                  fechaSoli,
                  fechaHoraIni,
                  fechaHoraFin,
                  diaDescanso,
                };
              }
            }
            arrayDias.push(form);
          }
          arrayCant.push(arrayDias);
        }
        arrayPost.push(arrayCant);
      }
      this.form1 = arrayPost;
    },

    async buscarCabezote(sol, indsol, indiceCantVehi, dia, tipo) {
      let dato = null;
      let fechaIni = null;
      let fechaFin = null;
      if (dia != null) {
        dato = this.form1[indsol][indiceCantVehi][dia].vehiculo.placa;
        fechaIni = this.form1[indsol][indiceCantVehi][dia].fechaHoraIni;
        fechaFin = this.form1[indsol][indiceCantVehi][dia].fechaHoraFin;
      } else {
        dato = this.listasForms.slct_vehiculo.placa;
        fechaIni = this.getDetSolicitud[0].tif_solicitudes_vehiculos.fecha_ini;
        fechaFin = this.getDetSolicitud[0].tif_solicitudes_vehiculos.fecha_fin;
      }
      this.cargando = true;
      if (dato) {
        let res = await this.validarStatus(dato, "V", fechaIni, fechaFin, tipo);

        if (res && res.length != 0) {
          //Validación 2 horas de reporte gps
          //Se obtiene fecha actual

          if (res[0].ultimo_registro) {
            const fechaAct = moment().format("YYYY-MM-DD HH:mm:ss");
            //Se le resta 2 horas a la fecha actual
            const fechaCorte = moment(fechaAct)
              .add(-2, "hours")
              .format("YYYY-MM-DD HH:mm:ss");
            //Se obtiene la fecha de reporte del vehículo
            const fechaGps = moment(
              res[0].ultimo_registro.fecha_hora_ultimo_reporte
            ).format("YYYY-MM-DD HH:mm:ss");

            if (fechaCorte > fechaGps) {
              this.cargando = false;
              await this.$swal({
                text:
                  "El vehículo no ha reportado en las 2 últimas horas, FRONTERA debe autorizar..",
                icon: "warning",
                confirmButtonText: "Aceptar!",
              });
              await this.selectAllVehi(indsol, indiceCantVehi, dia, res[0]);
              this.accion = 2;
            }
            this.cargando = false;
          } else {
            this.cargando = false;
            await this.$swal({
              title: "No es posible postular este vehículo!!",
              text: "El vehículo no tiene tramas satelitales a la fecha",
              icon: "error",
              confirmButtonText: "Aceptar!",
            });
            if (dia != null) {
              this.form1[indsol][indiceCantVehi][dia].vehiculo = {};
            } else {
              this.listasForms.slct_vehiculo = {};
              this.valVehi(indsol, indiceCantVehi);
            }
            this.cargando = false;
            return false;
          }

          if (res.validacion.error) {
            await this.msgDocumentacion(res);
            if (res.validacion.error.documentos !== undefined) {
              if (
                res.validacion.error.documentos.obligatorios !== undefined ||
                res.validacion.error.documentos.vencidos !== undefined ||
                res.validacion.error.documentos.no_verificados !== undefined
              ) {
                if (dia != null) {
                  this.form1[indsol][indiceCantVehi][dia].vehiculo = {};
                } else {
                  this.listasForms.slct_vehiculo = {};
                  this.valVehi(indsol, indiceCantVehi);
                }
                this.cargando = false;
                return false;
              }
            }

            if (res.validacion.error.inactivo == false) {
              if (dia != null) {
                this.form1[indsol][indiceCantVehi][dia].vehiculo = {};
              } else {
                this.listasForms.slct_vehiculo = {};
                this.valVehi(indsol, indiceCantVehi);
              }
              this.cargando = false;
              return false;
            }
          }

          //Validar si ya se encuentra con una inspección
          if (res.inspeccion != null) {
            if (res.inspeccion.estado == 4) {
              if (dia != null) {
                this.form1[indsol][indiceCantVehi][dia].vehiculo = {};
              } else {
                this.listasForms.slct_vehiculo = {};
                this.valVehi(indsol, indiceCantVehi);
              }
              await this.$swal({
                icon: "error",
                title: `La inspeccion del vehículo se encuentra en estado rechazado`,
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 1000,
                timerProgressBar: true,
              });
              this.cargando = false;
              return false;
            } else if (res.inspeccion.estado == 5) {
              await this.$swal({
                icon: "success",
                title: `La inspeccion del vehículo se encuentra aprobada con novedad`,
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 1000,
                timerProgressBar: true,
              });
            } else if (res.inspeccion.estado == 2) {
              await this.$swal({
                icon: "success",
                title: `La inspeccion del vehículo se encuentra aprobada`,
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 1000,
                timerProgressBar: true,
              });
            } else if (res.inspeccion.estado == 3) {
              await this.$swal({
                icon: "success",
                title: `La inspeccion del vehículo se encuentra autorizada`,
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 1000,
                timerProgressBar: true,
              });
            }
          } else {
            if (dia != null) {
              this.form1[indsol][indiceCantVehi][dia].vehiculo = {};
            } else {
              this.listasForms.slct_vehiculo = {};
              this.valVehi(indsol, indiceCantVehi);
            }
            await this.$swal({
              icon: "error",
              title: `El vehículo no cuenta con inspección vigente`,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 2000,
              timerProgressBar: true,
            });
            this.cargando = false;
            return false;
          }

          //Valida si se encuentra un turno activo
          if (res.turno.length > 0) {
            if (dia != null) {
              this.form1[indsol][indiceCantVehi][dia].vehiculo = {};
            } else {
              this.listasForms.slct_vehiculo = {};
              this.valVehi(indsol, indiceCantVehi);
            }
            await this.$swal({
              icon: "error",
              title: `El vehículo se encuentra activo dentro de un turno`,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 2000,
              timerProgressBar: true,
            });
            this.cargando = false;
            return false;
          }

          //Validar si ya se encuentra con una postulacion
          if (res.postulacion.length > 0) {
            if (dia != null) {
              this.form1[indsol][indiceCantVehi][dia].vehiculo = {};
            } else {
              this.listasForms.slct_vehiculo = {};
              this.valVehi(indsol, indiceCantVehi);
            }
            await this.$swal({
              icon: "error",
              title: `El vehículo cuenta con una postulacion activa`,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 2000,
              timerProgressBar: true,
            });
            this.cargando = false;
            return false;
          }

          //Valida la información completa
          if (res["info"] == false) {
            await this.$swal({
              icon: "error",
              title: `Hacen falta campos por diligenciar en el formulario de creacion de Vehiculos`,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 2000,
              timerProgressBar: true,
            });
            if (dia != null) {
              this.form1[indsol][indiceCantVehi][dia].vehiculo = {};
            } else {
              this.listasForms.slct_vehiculo = {};
              this.valVehi(indsol, indiceCantVehi);
            }
            this.cargando = false;
            return false;
          }

          //Valida si se encuentra en la linea perteneciente
          if (!res["has_lineas"]) {
            await this.$swal({
              icon: "error",
              title: `El vehiculo no se encuentra en la linea de negocio Transportes Internos`,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 2000,
              timerProgressBar: true,
            });
            if (dia != null) {
              this.form1[indsol][indiceCantVehi][dia].vehiculo = {};
            } else {
              this.listasForms.slct_vehiculo = {};
              this.valVehi(indsol, indiceCantVehi);
            }
            this.cargando = false;
            return false;
          }

          //Valida el tipo de vehiculo
          if (res[0].tipo_vehiculo_id != tipo) {
            if (dia != null) {
              this.form1[indsol][indiceCantVehi][dia].vehiculo = {};
            } else {
              this.listasForms.slct_vehiculo = {};
              this.valVehi(indsol, indiceCantVehi);
            }
            await this.$swal({
              icon: "error",
              title: `El tipo de vehículo no coincide.`,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 2000,
              timerProgressBar: true,
            });
            this.falgValidando = false;
            this.cargando = false;
            return false;
          }

          await this.$swal({
            icon: "success",
            title: "El vehículo es apto para ser postulado",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 1000,
            timerProgressBar: true,
          });
          await this.selectAllVehi(indsol, indiceCantVehi, dia, res[0]);
        } else {
          await this.$swal({
            icon: "error",
            title: "La placa ingresada no pertenece a ningún vehículo",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true,
          });
          if (dia != null) {
            this.form1[indsol][indiceCantVehi][dia].vehiculo = {};
          } else {
            this.listasForms.slct_vehiculo = {};
            this.valVehi(indsol, indiceCantVehi);
          }
        }
      }
      this.cargando = false;
    },

    async selectAllVehi(indsol, indiceCantVehi, dia, vehiculo) {
      this.listasForms.slct_vehiculo = {};
      if (dia != null) {
        this.form1[indsol][indiceCantVehi][dia].vehiculo = {
          placa: vehiculo.placa,
          id: vehiculo.id,
        };
      } else {
        for (let i = 0; i < this.diasPost; i++) {
          let fecha_solicitud = moment(
            this.getDetSolicitud[indsol].tif_solicitudes_vehiculos.fecha_ini
          )
            .add(i, "days")
            .format("YYYY-MM-DD");

          // Se valida si esta dentro de la fecha permitida
          if (fecha_solicitud >= this.hoy) {
            let diasSemana = moment(
              this.getDetSolicitud[indsol].tif_solicitudes_vehiculos.fecha_ini
            )
              .add(i, "days")
              .day();
            let numDias = this.getDetSolicitud[indsol].turno.dia_turno;

            let res = await this.validarDias(diasSemana, numDias);

            if (res == true) {
              this.form1[indsol][indiceCantVehi][i].vehiculo = {
                placa: vehiculo.placa,
                id: vehiculo.id,
              };
              this.listasForms.slct_placa = vehiculo.placa;
              this.form1[indsol][indiceCantVehi][i].fecha = fecha_solicitud;
            }
          }
        }
      }
    },

    valVehi(indsol, indiceCantVehi) {
      for (let i = 0; i < this.diasPost; i++) {
        this.form1[indsol][indiceCantVehi][i].vehiculo = {
          placa: null,
          id: null,
        };
      }
    },

    async buscarRemolque(sol, indsol, indiceCantVehi, dia) {
      let dato = null;
      let fechaIni = null;
      let fechaFin = null;
      if (dia != null) {
        dato = this.form1[indsol][indiceCantVehi][dia].remolque.placa;
        fechaIni = this.form1[indsol][indiceCantVehi][dia].fechaHoraIni;
        fechaFin = this.form1[indsol][indiceCantVehi][dia].fechaHoraFin;
      } else {
        dato = this.listasForms.slct_remolque.placa;
        fechaIni = this.getDetSolicitud[0].tif_solicitudes_vehiculos.fecha_ini;
        fechaFin = this.getDetSolicitud[0].tif_solicitudes_vehiculos.fecha_fin;
      }
      this.cargando = true;
      if (dato) {
        let res = await this.validarStatus(dato, "R", fechaIni, fechaFin);
        if (res && res.length != 0) {
          if (res.validacion.error) {
            await this.msgDocumentacion(res);
            this.falgValidando = false;
            if (res.validacion.error.documentos !== undefined) {
              if (
                res.validacion.error.documentos.obligatorios !== undefined ||
                res.validacion.error.documentos.vencidos !== undefined ||
                res.validacion.error.documentos.no_verificados !== undefined ||
                res.validacion.error.inactivo == false
              ) {
                if (dia != null) {
                  this.form1[indsol][indiceCantVehi][dia].remolque = {};
                } else {
                  this.listasForms.slct_remolque = {};
                  this.valRemol(indsol, indiceCantVehi);
                }
                this.cargando = false;
                return false;
              }
            }

            if (res.validacion.error.inactivo == false) {
              if (dia != null) {
                this.form1[indsol][indiceCantVehi][dia].remolque = {};
              } else {
                this.listasForms.slct_remolque = {};
                this.valRemol(indsol, indiceCantVehi);
              }
              this.cargando = false;
              return false;
            }
          }

          //Valida la información completa
          if (res["info"] == false) {
            await this.$swal({
              icon: "error",
              title: `Hacen falta campos por diligenciar en el formulario de creacion de Relmoques`,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 2000,
              timerProgressBar: true,
            });
            if (dia != null) {
              this.form1[indsol][indiceCantVehi][dia].remolque = {};
            } else {
              this.listasForms.slct_remolque = {};
              this.valRemol(indsol, indiceCantVehi);
            }
            this.cargando = false;
            return false;
          }

          //Valida si se encuentra en la linea perteneciente
          if (!res["has_lineas"]) {
            await this.$swal({
              icon: "error",
              title: `El Remolque no se encuentra en la linea de negocio Transporte Interno`,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 2000,
              timerProgressBar: true,
            });
            if (dia != null) {
              this.form1[indsol][indiceCantVehi][dia].remolque = {};
            } else {
              this.listasForms.slct_remolque = {};
              this.valRemol(indsol, indiceCantVehi);
            }
            this.cargando = false;
            return false;
          }

          //Valida si se encuentra con un turno activo
          if (res.turno.length > 0) {
            if (dia != null) {
              this.form1[indsol][indiceCantVehi][dia].remolque = {};
            } else {
              this.listasForms.slct_remolque = {};
              this.valRemol(indsol, indiceCantVehi);
            }
            await this.$swal({
              icon: "error",
              title: `El remolque se encuentra activo dentro de un turno`,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 2000,
              timerProgressBar: true,
            });
            this.cargando = false;
            return false;
          }

          //Valida si se encuentra con una postulación activa
          if (res.postulacion.length > 0) {
            if (dia != null) {
              this.form1[indsol][indiceCantVehi][dia].remolque = {};
            } else {
              this.listasForms.slct_remolque = {};
              this.valRemol(indsol, indiceCantVehi);
            }
            await this.$swal({
              icon: "error",
              title: `El remolque cuenta con una postulacion activa`,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 2000,
              timerProgressBar: true,
            });
            this.cargando = false;
            return false;
          }
          await this.$swal({
            icon: "success",
            title: "El remolque es apto para ser postulado",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 1000,
            timerProgressBar: true,
          });
          await this.selectAllRemo(indsol, indiceCantVehi, dia, res[0]);
        } else {
          await this.$swal({
            icon: "error",
            title:
              "El remolque digitado no se encuentra registrado, pruebe con otra placa.",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true,
          });
          if (dia != null) {
            this.form1[indsol][indiceCantVehi][dia].remolque = {};
          } else {
            this.listasForms.slct_remolque = {};
            this.valRemol(indsol, indiceCantVehi);
          }
        }
      }
      this.cargando = false;
    },

    async selectAllRemo(indsol, indiceCantVehi, dia, remolque) {
      this.listasForms.slct_remolque = {};
      if (dia != null) {
        this.form1[indsol][indiceCantVehi][dia].remolque = {
          placa: remolque.placa,
          id: remolque.id,
        };
      } else {
        for (let i = 0; i < this.diasPost; i++) {
          let fecha_solicitud = moment(
            this.getDetSolicitud[indsol].tif_solicitudes_vehiculos.fecha_ini
          )
            .add(i, "days")
            .format("YYYY-MM-DD");

          // Se valida si esta dentro de la fecha permitida
          if (fecha_solicitud >= this.hoy) {
            let diasSemana = moment(
              this.getDetSolicitud[indsol].tif_solicitudes_vehiculos.fecha_ini
            )
              .add(i, "days")
              .day();
            let numDias = this.getDetSolicitud[indsol].turno.dia_turno;

            let res = await this.validarDias(diasSemana, numDias);
            if (res == true) {
              this.form1[indsol][indiceCantVehi][i].remolque = {
                placa: remolque.placa,
                id: remolque.id,
              };
              this.form1[indsol][indiceCantVehi][i].fecha = fecha_solicitud;
            }
          }
        }
      }
    },

    valRemol(indsol, indiceCantVehi) {
      for (let i = 0; i < this.diasPost; i++) {
        this.form1[indsol][indiceCantVehi][i].remolque = {
          placa: null,
          id: null,
        };
      }
    },

    async buscarConductores(sol, indsol, indiceCantVehi, dia) {
      let dato = null;
      let fechaIni = null;
      let fechaFin = null;
      if (dia != null) {
        dato = this.form1[indsol][indiceCantVehi][dia].conductor1
          .numero_documento;
        fechaIni = this.form1[indsol][indiceCantVehi][dia].fechaHoraIni;
        fechaFin = this.form1[indsol][indiceCantVehi][dia].fechaHoraFin;
      } else {
        dato = this.listasForms.slct_conductor.numero_documento;
        fechaIni = this.getDetSolicitud[0].tif_solicitudes_vehiculos.fecha_ini;
        fechaFin = this.getDetSolicitud[0].tif_solicitudes_vehiculos.fecha_fin;
      }
      this.cargando = true;

      if (dato) {
        let res = await this.validarStatus(dato, "C", fechaIni, fechaFin);
        if (res && res.length != 0) {
          if (res.validacion.error) {
            await this.msgDocumentacion(res);
            if (res.validacion.error.documentos !== undefined) {
              if (
                res.validacion.error.documentos.obligatorios !== undefined ||
                res.validacion.error.documentos.vencidos !== undefined ||
                res.validacion.error.documentos.no_verificados !== undefined ||
                res.validacion.error.inactivo == false
              ) {
                if (dia != null) {
                  this.form1[indsol][indiceCantVehi][dia].conductor1 = {};
                } else {
                  this.listasForms.slct_conductor = {};
                  this.valCond(indsol, indiceCantVehi);
                }
                this.cargando = false;
                return false;
              }
            }

            if (res.validacion.error.inactivo == false) {
              if (dia != null) {
                this.form1[indsol][indiceCantVehi][dia].conductor1 = {};
              } else {
                this.listasForms.slct_conductor = {};
                this.valCond(indsol, indiceCantVehi);
              }
              this.cargando = false;
              return false;
            }
          }

          //Valida si se encuentra con la infomación completa
          if (res["info"] == false) {
            await this.$swal({
              icon: "error",
              title: `Hacen falta campos por diligenciar en el formulario de creacion de Conductores`,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 2000,
              timerProgressBar: true,
            });
            if (dia != null) {
              this.form1[indsol][indiceCantVehi][dia].conductor1 = {};
            } else {
              this.listasForms.slct_conductor = {};
              this.valCond(indsol, indiceCantVehi);
            }
            this.cargando = false;
            return false;
          }

          //Valida si se encuentra en la línea perteneciente
          if (!res["has_lineas"]) {
            await this.$swal({
              icon: "error",
              title: `El Conductor no se encuentra en la linea de negocio Transporte Interno`,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 2000,
              timerProgressBar: true,
            });
            if (dia != null) {
              this.form1[indsol][indiceCantVehi][dia].conductor1 = {};
            } else {
              this.listasForms.slct_conductor = {};
              this.valCond(indsol, indiceCantVehi);
            }
            this.cargando = false;
            return false;
          }

          //Valida si se encuentra con un turno activo
          if (res.turno.length > 0) {
            if (dia != null) {
              this.form1[indsol][indiceCantVehi][dia].conductor1 = {};
            } else {
              this.listasForms.slct_conductor = {};
              this.valCond(indsol, indiceCantVehi);
            }
            await this.$swal({
              icon: "error",
              title: `El conductor se encuentra activo dentro de un turno`,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 2000,
              timerProgressBar: true,
            });
            this.cargando = false;
            return false;
          }

          //Valida si se encuentra con una postulación activa
          if (res["postulacion"].length > 0) {
            if (dia != null) {
              this.form1[indsol][indiceCantVehi][dia].conductor1 = {};
            } else {
              this.listasForms.slct_conductor = {};
              this.valCond(indsol, indiceCantVehi);
            }
            await this.$swal({
              icon: "error",
              title: "El conductor cuenta con una postulacion activa",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 2000,
              timerProgressBar: true,
            });
            this.cargando = false;
            return false;
          }

          //Valida si se encuentra en la misma postulación
          /*  if (
            res[0]["id"] ==
            this.form1[indsol][indiceCantVehi][dia].conductor2.id
          ) {
            if (dia != null) {
              this.form1[indsol][indiceCantVehi][dia].conductor1 = {};
            } else {
              this.listasForms.slct_conductor = {};
              this.valCond(indsol, indiceCantVehi);
            }
            await this.$swal({
              icon: "error",
              title: "El conductor ya se encuentra en está postulación.",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 2000,
              timerProgressBar: true,
            });
            this.cargando = false;
            return false;
          } */

          await this.$swal({
            icon: "success",
            title: "El conductor es apto para ser postulado",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 1000,
            timerProgressBar: true,
          });
          await this.selectAllCond(indsol, indiceCantVehi, dia, res[0]);
          this.cargando = false;
          return true;
        } else {
          await this.$swal({
            icon: "error",
            title:
              "El conductor digitado NO se encuentra registrado, pruebe con otro documento.",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true,
          });
          if (dia != null) {
            this.form1[indsol][indiceCantVehi][dia].conductor1 = {};
          } else {
            this.listasForms.slct_conductor = {};
            this.valCond(indsol, indiceCantVehi);
          }
        }
      } else {
        if (dia != null) {
          this.form1[indsol][indiceCantVehi][dia].conductor1 = {};
        } else {
          this.listasForms.slct_conductor = {};
          this.valCond(indsol, indiceCantVehi);
        }
      }
      this.cargando = false;
    },

    async buscarConductores_2(sol, indsol, indiceCantVehi, dia) {
      let dato = null;
      let fechaIni = null;
      let fechaFin = null;
      if (dia != null) {
        dato = this.form1[indsol][indiceCantVehi][dia].conductor2
          .numero_documento;
        fechaIni = this.form1[indsol][indiceCantVehi][dia].fechaHoraIni;
        fechaFin = this.form1[indsol][indiceCantVehi][dia].fechaHoraFin;
      } else {
        dato = this.listasForms.slct_conductor_2.numero_documento;
        fechaIni = this.getDetSolicitud[0].tif_solicitudes_vehiculos.fecha_ini;
        fechaFin = this.getDetSolicitud[0].tif_solicitudes_vehiculos.fecha_fin;
      }
      this.cargando = true;

      if (dato) {
        if (dia != null) {
          dato = this.form1[indsol][indiceCantVehi][dia].conductor2
            .numero_documento;
        } else {
          dato = this.listasForms.slct_conductor_2.numero_documento;
        }

        let res = await this.validarStatus(dato, "C", fechaIni, fechaFin);
        if (res && res.length != 0) {
          if (res.validacion.error) {
            await this.msgDocumentacion(res);
            if (res.validacion.error.documentos !== undefined) {
              if (
                res.validacion.error.documentos.obligatorios !== undefined ||
                res.validacion.error.documentos.vencidos !== undefined ||
                res.validacion.error.documentos.no_verificados !== undefined ||
                res.validacion.error.inactivo == false
              ) {
                if (dia != null) {
                  this.form1[indsol][indiceCantVehi][dia].conductor2 = {};
                } else {
                  this.listasForms.slct_conductor_2 = {};
                  this.valCond2(indsol, indiceCantVehi);
                }
                this.cargando = false;
                return false;
              }
            }

            if (res.validacion.error.inactivo == false) {
              if (dia != null) {
                this.form1[indsol][indiceCantVehi][dia].conductor2 = {};
              } else {
                this.listasForms.slct_conductor_2 = {};
                this.valCond2(indsol, indiceCantVehi);
              }
              this.cargando = false;
              return false;
            }
          }

          //Valida si se encuentra en la linea perteneciente
          if (!res["has_lineas"]) {
            await this.$swal({
              icon: "error",
              title: `El Conductor no se encuentra en la linea de negocio Transporte Interno`,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 2000,
              timerProgressBar: true,
            });
            if (dia != null) {
              this.form1[indsol][indiceCantVehi][dia].conductor2 = {};
            } else {
              this.listasForms.slct_conductor_2 = {};
              this.valCond2(indsol, indiceCantVehi);
            }
            this.cargando = false;
            return false;
          }

          //Valida si se encuentra con un turno activo
          if (res.turno.length > 0) {
            if (dia != null) {
              this.form1[indsol][indiceCantVehi][dia].conductor2 = {};
            } else {
              this.listasForms.slct_conductor_2 = {};
              this.valCond2(indsol, indiceCantVehi);
            }
            await this.$swal({
              icon: "error",
              title: `El conductor se encuentra activo dentro de un turno`,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 2000,
              timerProgressBar: true,
            });
            this.cargando = false;
            return false;
          }

          //Valida si se encuentra con una postulación activa
          if (res["postulacion"].length > 0) {
            if (dia != null) {
              this.form1[indsol][indiceCantVehi][dia].conductor2 = {};
            } else {
              this.listasForms.slct_conductor_2 = {};
              this.valCond2(indsol, indiceCantVehi);
            }
            await this.$swal({
              icon: "error",
              title: "El conductor ya se encuentra en está postulación.",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 2000,
              timerProgressBar: true,
            });
            this.cargando = false;
            return false;
          }

          //Valida si se encuentra en la misma postulación
          /* if (
            res[0]["id"] ==
            this.form1[indsol][indiceCantVehi][dia].conductor1.id
          ) {
            if (dia != null) {
              this.form1[indsol][indiceCantVehi][dia].conductor2 = {};
            } else {
              this.listasForms.slct_conductor_2 = {};
              this.valCond2(indsol, indiceCantVehi);
            }
            await this.$swal({
              icon: "error",
              title: "El conductor ya se encuentra en está postulación.",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 2000,
              timerProgressBar: true,
            });
            this.cargando = false;
            return false;
          } */
          await this.$swal({
            icon: "success",
            title: "El conductor 2 es apto para ser postulado",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 1000,
            timerProgressBar: true,
          });
          await this.selectAllCond_2(indsol, indiceCantVehi, dia, res[0]);
          this.cargando = false;
          return true;
        } else {
          await this.$swal({
            icon: "error",
            title:
              "El conductor digitado NO se encuentra registrado, pruebe con otro documento.",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true,
          });
          if (dia != null) {
            this.form1[indsol][indiceCantVehi][dia].conductor2 = {};
          } else {
            this.listasForms.slct_conductor_2 = {};
            this.valCond2(indsol, indiceCantVehi);
          }
        }
      } else {
        if (dia != null) {
          this.form1[indsol][indiceCantVehi][dia].conductor2 = {};
        } else {
          this.listasForms.slct_conductor_2 = {};
          this.valCond2(indsol, indiceCantVehi);
        }
      }
      this.cargando = false;
    },

    async selectAllCond(indsol, indiceCantVehi, dia, conductor) {
      this.listasForms.slct_conductor = {};
      if (dia != null) {
        this.form1[indsol][indiceCantVehi][dia].conductor1 = {
          numero_documento: conductor.numero_documento,
          nombre: conductor.nombres + " " + conductor.apellidos,
          id: conductor.id,
        };
      } else {
        for (let i = 0; i < this.diasPost; i++) {
          let fecha_solicitud = moment(
            this.getDetSolicitud[indsol].tif_solicitudes_vehiculos.fecha_ini
          )
            .add(i, "days")
            .format("YYYY-MM-DD");

          // Se valida si esta dentro de la fecha permitida
          if (fecha_solicitud >= this.hoy) {
            let diasSemana = moment(
              this.getDetSolicitud[indsol].tif_solicitudes_vehiculos.fecha_ini
            )
              .add(i, "days")
              .day();
            let numDias = this.getDetSolicitud[indsol].turno.dia_turno;

            let res = await this.validarDias(diasSemana, numDias);
            if (res == true) {
              this.form1[indsol][indiceCantVehi][i].conductor1 = {
                numero_documento: conductor.numero_documento,
                nombre: conductor.nombres + " " + conductor.apellidos,
                id: conductor.id,
              };
              this.listasForms.slct_numero = conductor.numero_documento;
              this.form1[indsol][indiceCantVehi][i].fecha = fecha_solicitud;
            }
          }
        }
      }
    },

    valCond(indsol, indiceCantVehi) {
      for (let i = 0; i < this.diasPost; i++) {
        this.form1[indsol][indiceCantVehi][i].conductor1 = {
          numero_documento: null,
          nombre: null,
          id: null,
        };
      }
    },

    async selectAllCond_2(indsol, indiceCantVehi, dia, conductor) {
      this.listasForms.slct_conductor = {};
      this.listasForms.slct_conductor_2 = {};
      if (dia != null) {
        this.form1[indsol][indiceCantVehi][dia].conductor2 = {
          numero_documento: conductor.numero_documento,
          nombre: conductor.nombres + " " + conductor.apellidos,
          id: conductor.id,
        };
      } else {
        for (let i = 0; i < this.diasPost; i++) {
          let fecha_solicitud = moment(
            this.getDetSolicitud[indsol].tif_solicitudes_vehiculos.fecha_ini
          )
            .add(i, "days")
            .format("YYYY-MM-DD");

          // Se valida si esta dentro de la fecha permitida
          if (fecha_solicitud >= this.hoy) {
            let diasSemana = moment(
              this.getDetSolicitud[indsol].tif_solicitudes_vehiculos.fecha_ini
            )
              .add(i, "days")
              .day();
            let numDias = this.getDetSolicitud[indsol].turno.dia_turno;

            let res = await this.validarDias(diasSemana, numDias);
            if (res == true) {
              this.form1[indsol][indiceCantVehi][i].conductor2 = {
                numero_documento: conductor.numero_documento,
                nombre: conductor.nombres + " " + conductor.apellidos,
                id: conductor.id,
              };
              this.form1[indsol][indiceCantVehi][i].fecha = fecha_solicitud;
            }
          }
        }
      }
    },

    valCond2(indsol, indiceCantVehi) {
      for (let i = 0; i < this.diasPost; i++) {
        this.form1[indsol][indiceCantVehi][i].conductor2 = {
          numero_documento: null,
          nombre: null,
          id: null,
        };
      }
    },

    async validarStatus(dato, tipo, fechaIni, fechaFin, t_vehiculo) {
      let me = this;
      let url = "";
      let entidad = null;
      if (t_vehiculo == null) {
        t_vehiculo = null;
      }
      if (tipo == "C") {
        entidad = "Conductor";
        url = "api/tif/postulaciones/buscarConductores";
      } else if (tipo == "R") {
        entidad = "Remolque";
        url = "api/tif/postulaciones/buscarRemolque";
      } else if (tipo == "V") {
        entidad = "Vehiculo";
        url = "api/tif/postulaciones/buscarCabezote";
      }

      let params = {
        entidad,
        dato,
        t_vehiculo,
        fecha_ini: fechaIni,
        fecha_fin: fechaFin,
      };

      var res = null;

      await axios
        .get(url, {
          params,
        })
        .then((response) => {
          res = response.data;
        })
        .catch(function(e) {
          me.$swal({
            icon: "error",
            title: "Ocurrió un error - " + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });

      return res;
    },

    async msgDocumentacion(res) {
      this.cargando = false;
      if (res.validacion.error) {
        let erroHtml = "<div>";
        if (res.validacion.error.documentos) {
          if (res.validacion.error.documentos.obligatorios) {
            erroHtml += "<b>Pendiente:</b><br>";
            erroHtml += res.validacion.error.documentos.obligatorios.join(
              "<br>"
            );
          }
          if (res.validacion.error.documentos.vencidos) {
            erroHtml += "<br><b>Vencidos:</b><br>";
            for (
              let i = 0;
              i < res.validacion.error.documentos.vencidos.length;
              i++
            ) {
              erroHtml +=
                res.validacion.error.documentos.vencidos[i].documento +
                " - " +
                res.validacion.error.documentos.vencidos[i].fecha_vencimiento +
                "<br>";
            }
          }
          if (res.validacion.error.documentos.no_verificados) {
            erroHtml += "<br><b>No Verificados:</b><br>";
            erroHtml += res.validacion.error.documentos.no_verificados.join(
              "<br>"
            );
          }
        }
        if (res.validacion.error.msg) {
          erroHtml += "<br><b>La entidad se encuentra inactiva.</b><br>";
        }
        erroHtml += "</div>";
        await this.$swal({
          html: erroHtml,
          showCancelButton: false,
          focusConfirm: true,
          icon: "error",
          title: "Aviso en documentacion",
          showConfirmButton: false,
          showCloseButton: true,
          footer:
            "<small style='text-align:center'><b>Desde el 1 de septiembre se aplicaran las restricciones</b>, por favor tenga al dia la documentacion de sus vehiculos, para mas indata_solicitudacion comuniquese con la mesa de ayuda al siguiente numero <b>3176431376 Opción 3</b></small>",
        });
      }
    },

    back() {
      return this.$router.replace("/Tif/Postulaciones");
    },

    async savePostulacion(
      solDet,
      indicesol,
      indiceCantVehi,
      sol,
      producto_id,
      empresa,
      bloque
    ) {
      this.cargando = true;
      let formEnvio = [];
      let arrayVehi = this.form1[indicesol][indiceCantVehi];

      for (let i = 0; i < arrayVehi.length; i++) {
        if (this.accion == 2) {
          arrayVehi[i].estado = 4;
        } else {
          arrayVehi[i].estado = 1;
        }

        //Se agrega campo producto_id al arreglo
        arrayVehi[i].producto_id = producto_id;
        arrayVehi[i].empresa_id = empresa;
        arrayVehi[i].bloque = bloque;

        if (arrayVehi[i].vehiculo != null) {
          let id = null;
          if (arrayVehi[i].id) {
            id = arrayVehi[i].id;
          }
          let form = {
            id,
            det_solicitud_vehiculo: solDet,
            solicitud_vehiculo: sol,
            vehiculo: arrayVehi[i].vehiculo,
            producto: arrayVehi[i].producto_id,
            remolque: arrayVehi[i].remolque,
            conductor: arrayVehi[i].conductor1,
            conductor_2: arrayVehi[i].conductor2,
            orden: arrayVehi[i].orden,
            fecha: arrayVehi[i].fecha,
            fecha_ini: arrayVehi[i].fechaHoraIni,
            fecha_fin: arrayVehi[i].fechaHoraFin,
            empresa_id: arrayVehi[i].empresa_id,
            bloque: arrayVehi[i].bloque,
            estado: arrayVehi[i].estado,
            pos_dia: arrayVehi[i].pos_dia,
          };

          formEnvio.push(form);
        }
      }

      if (this.listasForms.slct_placa && this.listasForms.slct_numero) {
        await axios({
          method: "POST",
          url: "/api/tif/postulaciones/savePostulacion?accion=" + this.accion,
          data: formEnvio,
        })
          .then(async (response) => {
            await this.getIndex();
            if (response.data.msg) {
              this.$swal({
                icon: "success",
                title: response.data.msg,
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 1000,
                timerProgressBar: true,
              });
            }
            this.cargando = false;
          })
          .catch((e) => {
            this.cargando = false;
            this.$swal({
              icon: "error",
              title: `Ha ocurrido un error`,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      } else {
        this.cargando = false;
        this.$swal({
          showCancelButton: false,
          focusConfirm: true,
          icon: "error",
          title:
            "Se debe ingresar un vehículo y al menos el primer conductor para una postulación",
          showConfirmButton: false,
          showCloseButton: true,
        });
      }
    },

    async saveDetPostulacion(
      sol,
      indsol,
      indiceCantVehi,
      dia,
      t,
      producto_id,
      empresa,
      bloque
    ) {
      this.cargando = true;
      let estado = 1;

      if (this.accion == 2) {
        estado = 4;
      }

      if (
        this.form1[indsol][indiceCantVehi][dia].vehiculo.id &&
        this.form1[indsol][indiceCantVehi][dia].conductor1.id
      ) {
        if (t == 1) {
          if (this.form1[indsol][indiceCantVehi][dia].remolque.id) {
            if (this.form1[indsol][indiceCantVehi][dia].conductor2.id) {
              this.params = {
                id: this.form1[indsol][indiceCantVehi][dia].id,
                solicitud_vehiculo_id: this.$route.params.solicitud.id,
                det_solicitud_vehiculo_id: sol,
                vehiculo_id: this.form1[indsol][indiceCantVehi][dia].vehiculo
                  .id,
                placa_vehi: this.form1[indsol][indiceCantVehi][dia].vehiculo
                  .placa,
                remolque_id: this.form1[indsol][indiceCantVehi][dia].remolque
                  .id,
                placa_remol: this.form1[indsol][indiceCantVehi][dia].remolque
                  .placa,
                conductor_id: this.form1[indsol][indiceCantVehi][dia].conductor1
                  .id,
                conductor_ce: this.form1[indsol][indiceCantVehi][dia].conductor1
                  .cedula,
                conductor_nom: this.form1[indsol][indiceCantVehi][dia]
                  .conductor1.nombre,
                conductor_2_id: this.form1[indsol][indiceCantVehi][dia]
                  .conductor2.id,
                conductor_2_nom: this.form1[indsol][indiceCantVehi][dia]
                  .conductor2.nombre,
                conductor_2_ce: this.form1[indsol][indiceCantVehi][dia]
                  .conductor2.cedula,
                orden: this.form1[indsol][indiceCantVehi][dia].orden,
                fecha_ini: this.form1[indsol][indiceCantVehi][dia].fechaHoraIni,
                fecha_fin: this.form1[indsol][indiceCantVehi][dia].fechaHoraFin,
                estado: estado,
                producto_id: producto_id,
                empresa_id: empresa,
                bloque: bloque,
                pos_dia: this.form1[indsol][indiceCantVehi][dia].pos_dia,
              };
            } else {
              this.params = {
                id: this.form1[indsol][indiceCantVehi][dia].id,
                solicitud_vehiculo_id: this.$route.params.solicitud.id,
                det_solicitud_vehiculo_id: sol,
                vehiculo_id: this.form1[indsol][indiceCantVehi][dia].vehiculo
                  .id,
                placa_vehi: this.form1[indsol][indiceCantVehi][dia].vehiculo
                  .placa,
                remolque_id: this.form1[indsol][indiceCantVehi][dia].remolque
                  .id,
                placa_remol: this.form1[indsol][indiceCantVehi][dia].remolque
                  .placa,
                conductor_id: this.form1[indsol][indiceCantVehi][dia].conductor1
                  .id,
                conductor_nom: this.form1[indsol][indiceCantVehi][dia]
                  .conductor1.nombre,
                conductor_ce: this.form1[indsol][indiceCantVehi][dia].conductor1
                  .cedula,
                orden: this.form1[indsol][indiceCantVehi][dia].orden,
                fecha_ini: this.form1[indsol][indiceCantVehi][dia].fechaHoraIni,
                fecha_fin: this.form1[indsol][indiceCantVehi][dia].fechaHoraFin,
                estado: estado,
                producto_id: producto_id,
                empresa_id: empresa,
                bloque: bloque,
                pos_dia: this.form1[indsol][indiceCantVehi][dia].pos_dia,
              };
            }
          } else {
            this.$swal({
              showCancelButton: false,
              focusConfirm: true,
              icon: "error",
              title: "se necesita un remolque para esta postulacion",
              showConfirmButton: false,
              showCloseButton: true,
            });
          }
        } else {
          if (this.form1[indsol][indiceCantVehi][dia].conductor2.id) {
            this.params = {
              id: this.form1[indsol][indiceCantVehi][dia].id,
              solicitud_vehiculo_id: this.$route.params.solicitud.id,
              det_solicitud_vehiculo_id: sol,
              vehiculo_id: this.form1[indsol][indiceCantVehi][dia].vehiculo.id,
              placa_vehi: this.form1[indsol][indiceCantVehi][dia].vehiculo
                .placa,
              conductor_id: this.form1[indsol][indiceCantVehi][dia].conductor1
                .id,
              conductor_nom: this.form1[indsol][indiceCantVehi][dia].conductor1
                .nombre,
              conductor_ce: this.form1[indsol][indiceCantVehi][dia].conductor1
                .cedula,
              conductor_2_id: this.form1[indsol][indiceCantVehi][dia].conductor2
                .id,
              conductor_2_nom: this.form1[indsol][indiceCantVehi][dia]
                .conductor2.nombre,
              conductor_2_ce: this.form1[indsol][indiceCantVehi][dia].conductor2
                .cedula,
              orden: this.form1[indsol][indiceCantVehi][dia].orden,
              fecha_ini: this.form1[indsol][indiceCantVehi][dia].fechaHoraIni,
              fecha_fin: this.form1[indsol][indiceCantVehi][dia].fechaHoraFin,
              estado: estado,
              producto_id: producto_id,
              empresa_id: empresa,
              bloque: bloque,
              pos_dia: this.form1[indsol][indiceCantVehi][dia].pos_dia,
            };
          } else {
            this.params = {
              id: this.form1[indsol][indiceCantVehi][dia].id,
              solicitud_vehiculo_id: this.$route.params.solicitud.id,
              det_solicitud_vehiculo_id: sol,
              vehiculo_id: this.form1[indsol][indiceCantVehi][dia].vehiculo.id,
              placa_vehi: this.form1[indsol][indiceCantVehi][dia].vehiculo
                .placa,
              conductor_id: this.form1[indsol][indiceCantVehi][dia].conductor1
                .id,
              conductor_nom: this.form1[indsol][indiceCantVehi][dia].conductor1
                .nombre,
              conductor_ce: this.form1[indsol][indiceCantVehi][dia].conductor1
                .cedula,
              orden: this.form1[indsol][indiceCantVehi][dia].orden,
              fecha_ini: this.form1[indsol][indiceCantVehi][dia].fechaHoraIni,
              fecha_fin: this.form1[indsol][indiceCantVehi][dia].fechaHoraFin,
              estado: estado,
              producto_id: producto_id,
              empresa_id: empresa,
              bloque: bloque,
              pos_dia: this.form1[indsol][indiceCantVehi][dia].pos_dia,
            };
          }
        }
        if (this.params) {
          axios({
            method: "PUT",
            url:
              "/api/tif/postulaciones/saveDetPostulacion?accion=" + this.accion,
            data: this.params,
          })
            .then((response) => {
              this.getIndex();
              this.$swal({
                icon: "success",
                title: "Se ingreso la postulación...",
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 1000,
                timerProgressBar: true,
              });
              this.cargando = false;
            })
            .catch((e) => {
              this.cargando = false;
              this.$swal({
                icon: "error",
                title: `Ha ocurrido un error`,
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
            });
        }
      } else {
        this.cargando = false;
        this.$swal({
          showCancelButton: false,
          focusConfirm: true,
          icon: "error",
          title:
            "Se debe ingresar un vehiculo y al menos el primer conductor para una postulación",
          showConfirmButton: false,
          showCloseButton: true,
        });
      }
    },

    enviarCorreo() {
      axios
        .get("/api/tif/postulaciones/enviarCorreo", {
          params: {
            solicitud_id: this.$route.params.solicitud.id,
            empresa_id: this.$route.params.solicitud.empresa_id,
          },
        })
        .then((response) => {
          this.$swal({
            icon: "success",
            title: "Correo enviado satisfactoriamente",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        })
        .catch(function(error) {
          this.$swal({
            icon: "error",
            title: "Error, en el envio de correo:" + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    validarEstado(insol, indiceCantVehi, dia) {
      if (this.form1.length > 0) {
        let estado = this.form1[insol][indiceCantVehi][dia].estado;
        if (estado == null || estado == 1) {
          return true;
        }
        return false;
      }
    },

    validarDias(diaSemana, numDias) {
      if (diaSemana == 0) {
        diaSemana = 7;
      }

      let search = numDias.find((d) => d.dia_semana === diaSemana);
      if (search != undefined) {
        return true;
      }
      return false;
    },

    editDetPost(insol, indiceCantVehi, dia, fecha) {
      if (!this.form1[insol][indiceCantVehi][dia].conductor1) {
        this.form1[insol][indiceCantVehi][dia].conductor1 = {};
      }

      if (!this.form1[insol][indiceCantVehi][dia].conductor2) {
        this.form1[insol][indiceCantVehi][dia].conductor2 = {};
      }

      if (!this.form1[insol][indiceCantVehi][dia].vehiculo) {
        this.form1[insol][indiceCantVehi][dia].vehiculo = {};
      }

      if (!this.form1[insol][indiceCantVehi][dia].remolque) {
        this.form1[insol][indiceCantVehi][dia].remolque = {};
      }

      if (this.form1[insol][indiceCantVehi][dia].estado !== 2) {
        this.form1[insol][indiceCantVehi][dia].fecha = fecha;
        this.form1[insol][indiceCantVehi][dia].fecha_ver = moment(fecha).format(
          "dddd, LL"
        );
        this.selectVehi = insol + "_" + indiceCantVehi + "_" + dia;
      } else {
        this.$swal({
          icon: "error",
          title: "Postulacion usada, no se puede editar",
          toast: true,
          position: "bottom-end",
          showConfirmButton: false,
          timer: 4000,
          timerProgressBar: true,
        });
      }
    },
  },

  mounted() {
    this.getIndex();
  },
};
</script>
